@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .anim {
    @apply transition-all duration-[400ms] ease-in-out;
  }
}

body{
  background-color: #f9f6ff;
  font-family: Inter, sans-serif;
  color: rgb(0, 0, 0);
}
.hd{
  font-weight: 600;
  letter-spacing: -1px;
}

li::marker {
  color: #6d71ad;
}

li {
  list-style-type: '▸';
  padding-inline-start: 1.5ch;
  font-size: larger;
}

p{
  font-size: larger;
}
/*---------------HP Classics---------------*/


.noscr::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}


/*centering absolute stuff*/

.centerh{      
  left: 50%;                        
  transform: translate(-50%, 0)
}

.centerv{      
  top: 50%;                        
  transform: translate(0, -50%)
  
}

.centerrr{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}


/*Selection controls*/

::-moz-selection {
  color: black; 
  background: #2f36f5c7;
}

::selection {
  color: rgb(255, 255, 255); 
  background: #2F36F5c7;
}

.noselect {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}


/*media hacks*/

.pc{
  visibility: visible;
  display: flex;
}

.mob{
  visibility: hidden;
  display: none;
}

.hide{
  display: none;
  visibility: hidden;
}


/*Scrollbar customized*/

::-webkit-scrollbar-track {
background-color: transparent;
overflow: overlay;
width: 5px;
z-index: 100;
}

::-webkit-scrollbar {
width: 5px;
overflow: overlay;
z-index: 100;
height: 4px;
}

::-webkit-scrollbar-thumb {
visibility: visible;
border-radius: 100px;

background-color: #d8d8d8;
z-index: 150;
}


/*z-index hacks*/

.toppp{
  z-index: 9999;
}

.topppp{
  z-index: 99999;
}
.toppppp{
  z-index: 9999999;
}

.bottt{
  z-index: -9999; 
}


/*animation*/

.scaleup{
  transition: scale 0.5s;
  transition-timing-function: ease-in-out;
}
.scaleup:hover{
  scale: 101%;
}

/*others*/

abbr[title] {
border-bottom: none !important;
cursor: inherit !important;
text-decoration: none !important;
}

@media screen and (max-width: 768px) {

.mob{
visibility: visible;
display:flex;
}
.pc{
visibility: hidden;
display: none;
}

p{
  font-size: medium;
}

li{
  font-size: medium;
}

}


/*---------------HP Classics---------------*/