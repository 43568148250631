.carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .carousel {
    display: flex;
    scroll-snap-type: x proximity;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: transform 0.5s ease;
  }
  
  .carousel-item {
    width: 100vw;
    scroll-snap-stop: always;
    scroll-snap-align: start;
  }
  
  .navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
